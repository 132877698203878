// Generated by @nuxtjs/svg-sprite
export const sprites = {
  "icons": () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/assets/sprite/gen/icons.svg").then(r => r.default || r),
  "letters": () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/assets/sprite/gen/letters.svg").then(r => r.default || r),
  "socials": () => import("/home/runner/work/77bit-site-v6/77bit-site-v6/assets/sprite/gen/socials.svg").then(r => r.default || r)
}
export const spriteClass = "";

export const spriteClassPrefix = "";

export const defaultSprite = "icons";
